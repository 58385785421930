.result-message
  line-height: 22px
  font-size: 16px

  a
    color: rgb(89, 111, 214)
    font-weight: 600
    text-decoration: none

.hidden
  display: none

#card-error
  color: rgb(105, 115, 134)
  font-size: 16px
  line-height: 20px
  margin-top: 12px
  text-align: center

#card-element
  border-radius: 4px 4px 0 0
  padding: 12px
  max-height: 64px
  width: 100%
  background: white
  box-sizing: border-box

.spinner,
.spinner:before,
.spinner:after
  border-radius: 50%

.spinner
  color: #ffffff
  font-size: 22px
  text-indent: -99999px
  margin: 0px auto
  position: relative
  width: 20px
  height: 20px
  box-shadow: inset 0 0 0 2px
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)

  &:before
    width: 10.4px
    height: 20.4px
    background: #5469d4
    border-radius: 20.4px 0 0 20.4px
    top: -0.2px
    left: -0.2px
    -webkit-transform-origin: 10.4px 10.2px
    transform-origin: 10.4px 10.2px
    -webkit-animation: loading 2s infinite ease 1.5s
    animation: loading 2s infinite ease 1.5s

  &:after
    width: 10.4px
    height: 10.2px
    background: #5469d4
    border-radius: 0 10.2px 10.2px 0
    top: -0.1px
    left: 10.2px
    -webkit-transform-origin: 0px 10.2px
    transform-origin: 0px 10.2px
    -webkit-animation: loading 2s infinite ease
    animation: loading 2s infinite ease

.spinner:before,
.spinner:after
  position: absolute
  content: ""

@keyframes loading
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)
  100%
    -webkit-transform: rotate(360deg)
    transform: rotate(360deg)

.payment-button-container
  margin-top: 18px
  padding-top: 18px
  border-top: 1px solid $brand-gray-border

.payment-button-container-rub
  margin: 18px
  padding: 18px
  border-top: 1px solid $brand-gray-border
  display: flex
  gap: 16px
  flex-direction: column

.card-element
  color: $brand-dark-blue
  font-size: 16px
  line-height: 26px
  letter-spacing: 0.32px
  display: grid
  grid-template-columns: 1fr 3fr
  align-items: center
  +media-query(tablet)
    display: block

  .StripeElement
    margin: 4px 0 10px 0
    font-family: $font-family-base
    outline: none
    box-sizing: border-box
    border: 1px solid $brand-gray-border
    border-radius: 4px
    background: #fff
    color: $brand-gray-dark
    width: 100%
    padding: 10px 16px 10px 16px
    letter-spacing: 0.32px