// Position
.relative
  position: relative


// Display
.flex
  display: flex

.block
  display: block