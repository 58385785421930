.carousel
  display: flex
  justify-content: space-between
  height: 72px

  &__slider
    width: 315px
    +media-query(tablet)
      width: 100%

  &__button
    width: 33px
    height: 63px
    background: #ffffff
    border-radius: 6px
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1)
    display: block
    position: relative

    &_back
      svg
        transform: rotate(90deg)

    &_next
      svg
        transform: rotate(-90deg)

  &__item
    background: #ffffff
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    box-sizing: border-box
    padding: 11px 0
    position: relative
    font-size: 15px
    line-height: 20px
    letter-spacing: 0.15px
    color: $brand-dark-blue
    cursor: pointer
    transition: color 300ms
    margin-left: 4px
    margin-right: 4px
    display: flex
    flex-direction: column
    align-items: center

    svg
      fill: #33b92d
      content: ''
      position: absolute
      right: 4px
      top: 9px
      width: 21px
      height: 16px
      transition: opacity 300ms


    span
      display: block
      transition: color 300ms
      color: #848484
      font-size: 12px
      line-height: 20px
      letter-spacing: 0.12px
      text-transform: uppercase
      padding-right: 10px

    &:hover
      color: $brand-green
      font-weight: bold

      span
        color: $brand-green
        font-weight: bold

    &_active
      background: $brand-green
      color: #ffffff

      span
        color: #ffffff

      svg
        color: #ffffff
        fill: #ffffff

      &:hover
        color: #ffffff
        font-weight: bold

        span
          color: #ffffff
          font-weight: bold

    &_disabled
      opacity: 0.6

      svg
        fill: $brand-red

      &:hover
        color: $brand-red

        span
          color: $brand-red


.time-slot-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-row-gap: 10px



