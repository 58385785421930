=media-query($media-query)
  @if $media-query == phone
    @media only screen and (max-width: $screen-phone)
      @content

  @if $media-query == tablet
    @media only screen and (max-width: $screen-tablet)
      @content

  @if $media-query == desktop
    @media only screen and (max-width: $screen-desktop)
      @content

  @if $media-query == wide
    @media only screen and (max-width: $screen-wide)
      @content
