.custom_select
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  width: 100%
  height: 26px
  margin: 0
  padding: 0
  transform: translateY(1px)
  white-space: nowrap
  box-sizing: border-box
  overflow: hidden
  outline: none
  color: #1B214A
  font-size: 18px
  line-height: 26px
  transition: all 0.2s ease-in

  &_wrapper
    cursor: pointer
    display: inline-block
    position: relative
    width: 100%
    height: 26px
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  span
    display: inline-block
    margin: 0 0 0 0

    &:after
      content: ''
      width: 5px
      height: 1px
      background: #585d64
      display: inline-block
      vertical-align: middle
      margin: 0 12px 0 11px
      transform: translateY(-1px)

  &.active
    z-index: 999

  &:before
    content: ''
    display: inline-block
    vertical-align: top
    width: 10px
    height: 6px
    position: absolute
    right: 0
    top: 21px

.select-options
  display: none
  position: absolute
  top: calc(100% + 45px)
  right: auto
  left: 0
  margin: 0
  padding: 21px 0 29px 0
  list-style: none
  overflow: hidden
  text-align: left
  border: none
  white-space: nowrap
  z-index: 5
  width: 318px
  border-radius: 6px
  background: #fff
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.07)

  &::-webkit-scrollbar
    display: none

  li
    margin: 0
    font-size: 17px
    line-height: 28px
    letter-spacing: 0.085px
    font-weight: 400
    color: #A5A5A5
    width: 100%
    box-sizing: border-box
    padding: 5px 55px 5px 55px
    position: relative
    transition: color 300ms, background 300ms
    list-style: none


  &:after
    content: ''
    width: 12px
    height: 7px
    background: url(../../images/icons/icon-dropdown.svg) no-repeat center 100%
    position: absolute
    right: 15px
    top: 17px

.ticket-select-time__input .custom_select_wrapper
  z-index: 15
  height: 34px
  box-sizing: border-box
  width: 150px

.ticket-select-time__input .input-wrap--select select, select.input-wrap--select
  -webkit-box-shadow: none
  -webkit-appearance: none
  border: none
  padding: 8px 35px 6px 17px
  color: #A5A5A5
  font-size: 15px
  line-height: 26px
  outline: none
  cursor: pointer
  width: 149px
  letter-spacing: 0.15px
  background: transparent

.select-hidden
  display: none
  visibility: hidden

.ticket-select-time__input div.custom_select_wrapper div.custom_select
  font-size: 15px
  line-height: 26px
  letter-spacing: 0.15px
  color: #A5A5A5
  padding: 7px 32px 1px 16px

.ticket-select-time__input .custom_select_wrapper ul.select-options
  width: 151px
  padding: 10px 0 8px 0
  top: calc(100% + 15px)

.ticket-select-time__input .custom_select_wrapper ul.select-options > li
  padding: 5px 26px 5px 17px
  font-size: 15px
  line-height: 22px

@keyframes fadeIn
  0%
    max-height: 0px

  100%
    max-height: 200px


@keyframes fadeOut
  0%
    max-height: 200px
    display: block

  100%
    max-height: 0px
    display: none

.select-options__close
  animation: fadeOut 0.5s
  overflow: hidden

.select-options__open
  animation: fadeIn 0.5s
  overflow: hidden
  display: block

.ticket-select-time__input .custom_select_wrapper ul.select-options > li.is-selected, .ticket-select-time__input .custom_select_wrapper ul.select-options > li:hover
  color: #1B214A

