.button
  height: 54px
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  border: none
  border-radius: 6px
  background: $brand-orange
  transition: background 300ms


  &[data-active="true"]
    background: $brand-green
    box-shadow: 0px 3px 15px rgba(63, 195, 57, 0.23)

    &:hover
      background: $brand-dark-green

  &_fullWidth
    width: 100%

  > span
    color: #FFFFFF
    font-size: 17px
    line-height: 26px
    padding: 0 24px 0 24px
    display: flex
    +media-query(wide)
      padding: 0 8px 0 8px
    +media-query(tablet)
      font-size: 14px

    &:disabled
      color: $brand-gray-medium

  i
    padding-right: 8px

  &:hover
    background: $brand-dark-orange

  &_blue
    background: $brand-blue
    box-shadow: 0px 3px 15px rgb(43 56 143 / 31%)

    &:hover
      background: $brand-dark-blue

    &[disabled]
      background-color: $brand-gray-dark

      span
        opacity: 0.8

  &_dark-blue
    background: $brand-dark-blue
    box-shadow: 0px 3px 15px rgb(33 46 133 / 31%)

    &:hover
      background: #111329

  &_orange
    background: $brand-orange
    box-shadow: 0px 3px 15px rgb(246 146 30 / 28%)

    &:hover
      background: $brand-dark-orange

  &_green
    background: $brand-green
    box-shadow: 0px 3px 15px rgb(63 195 57 / 23%)

    &:hover
      background: $brand-dark-green

  &_gray
    background: $brand-gray-xlight
    border-color: $brand-gray-border
    box-shadow: 0px 3px 15px rgb(40 40 40 / 17%)

    > span
      color: $brand-dark-blue

    &:hover
      background: $brand-gray

  &_small
    height: 34px

  &_medium
    height: 54px

  &_large
    height: 64px




