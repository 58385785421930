#main-footer
  background: #ffffff
  height: auto
  display: flex
  align-items: center    
  width: 100%
  padding-top: 20px
  padding-bottom: 20px
  box-shadow: 0px -4px 10px 0px #e5e7eb

  .footer-wrap
    display: flex
    flex: 1
    flex-wrap: wrap
    font-size: 16px
    max-width: 1170px    
    margin: 0 auto
    +media-query(phone)
      flex-direction: row
    .footer-full 
      padding: 10px
      text-align: center
      width: 100%

      
    .footer-column
      padding: 10px
      text-align: center
      flex: 33.3%
      +media-query(phone)
        flex: 100%
      .footer-column-center-wrapper 
        display: inline-block
        a
          justify-content: left
      
      img 
        flex: 1
        margin-right: 5px
      a
        display: flex
        width: 100%
        padding: 10px
        align-items: center
        justify-content: center
        color: #1b214a
        
        svg
          margin-right: 10px
          width: 20px        
    
    .footer-contacts
      div
        a
          padding: 0

  .payment-card-logo  
    max-height: 30px
    margin: 10px
  .payment-card-logo-mir
    max-height: 25px
  .payment-card-logo-modulbank
    max-height: 40px
    margin-top: -5px
  .footer-photo
    width: 12em
    height: 4em
    display: block
    background: url('../../../images/traveldesk-footer.jpg') no-repeat center/cover // Update with your photo's path
    +media-query(tablet)
      display: none
      font-size: 20px
      margin: 0 auto
    +media-query(phone)
      display: none
      font-size: 16px
      margin: 0 auto

  .container
    display: flex
    justify-content: space-between
    align-items: center
    padding: 52px 0 50px 0
    +media-query(wide)
      padding: 42px 20px 40px 20px
    +media-query(tablet)
      padding: 29px 20px 24px 20px

.footer-left
  transform: translateY(-1px)
  +media-query(tablet)
    display: none

  a
    line-height: 26px
    color: $brand-dark-blue

    i
      display: inline-block
      vertical-align: baseline
      margin-right: 9px

.footer-right
  +media-query(phone)
    align-items: center
    width: 100%
  display: flex
  flex-direction: column
  align-items: flex-end

  .footer-photo
    width: 12em
    height: 4em
    display: block
    background: url('../../../images/traveldesk-footer.jpg') no-repeat center/cover // Update with your photo's path
    +media-query(tablet)
      display: none
      font-size: 20px
      margin: 0 auto
    +media-query(phone)
      display: none
      font-size: 16px
      margin: 0 auto

    img
      display: block
      width: 100%
      height: auto
    a
      display: block
      height: 100%
      width: 100%

.footer-contact--email
  padding-right: 21px
  font-size: 18px

  i
    width: 20px
    height: 15px
    transform: translateY(1px)
    background: url('../../../images/icons/icon-email.svg') no-repeat center 100%

.footer-contact--phone
  transform: translateY(-1px)
  font-size: 19px

  i
    width: 21px
    height: 21px
    transform: translateY(3px)
    margin-right: 8px
    background: url('../../../images/icons/icon-phone.svg') no-repeat center 100%

  span
    color: $brand-orange

.footer-copyright
  color: #727272
  font-size: 16px
  line-height: 26px
  +media-query(tablet)
    font-size: 14px