// Colors
$brand-gray-xDark: #5F5F5F
$brand-gray-dark: #848484
$brand-gray-border: #D9D9D9
$brand-gray-medium: #A5A5A5
$brand-gray: #F5F6FA
$brand-gray-light: #FBFBFB
$brand-gray-xlight: #EFEFEF

$brand-green: #33B92D
$brand-dark-green: #139D0D
$brand-blue: #2B388F
$brand-light-blue: #3849BB
$brand-dark-blue: #1B214A
$brand-orange: #F6921E
$brand-dark-orange: #E28314

$brand-red: #EC4210

$brand-dark-red: #c93a0e


$disabled-color: $brand-gray-medium

$background-color: #F5F6FA

$text-color: $brand-dark-blue
$body-bg: #fff
$placeholder-color: $brand-gray-medium

$border-color: #E5E7EB
$border-hover-color: $brand-gray
$border-active-color: $text-color


// Typography
$font-family-base: 'Cabin', sans-serif
$font-size-base: 15px
$font-size-small: 14px

$font-weight-normal: 400
$line-height-base: 22px


// Media queries breakpoints
$screen-wide: 1200px
$screen-desktop: 960px
$screen-tablet: 760px
$screen-phone: 360px

// Grid system


// Objects


// Scaffolding

