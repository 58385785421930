.ticket-title
  +media-query(tablet)
    font-size: 26px
    line-height: 31px

  span
    display: block
    color: $brand-green
    font-size: 20px
    line-height: 31px
    margin: 0 0 15px 0
    +media-query(tablet)
      font-size: 17px

    svg
      vertical-align: top
      transform: translateY(7px)
      margin: 0 10px 0 0

.ticket-select-label
  font-size: 26px
  line-height: 31px
  justify-content: space-between
  display: flex
  flex-direction: row
  +media-query(tablet)
    font-size: 22px

  svg
    transform: translateY(3px)

.ticket-wrap
  padding-bottom: 69px

.ticket-item
  margin-top: 30px

  &-container
    border-right: 1px solid $border-color
    color: $brand-gray-medium
    font-size: 19px
    line-height: 26px
    padding: 30px 30px 24px 36px
    +media-query(wide)
      padding: 20px 20px 14px 26px
      font-size: 16px
      line-height: 24px
    +media-query(desktop)
      min-width: 33.33%
      max-width: 33.33%
      border: none

  &--preview
    display: flex
    flex-direction: row
    background: #ffffff
    border-radius: 8px
    overflow: hidden
    +media-query(desktop)
      flex-wrap: wrap

    > div
      box-sizing: border-box
      align-self: center
      +media-query(tablet)
        align-self: auto

  &__image
    +media-query(wide)
      min-width: 220px
      max-width: 220px
    +media-query(desktop)
      min-width: 33.33%
      max-width: 33.33%
    +media-query(tablet)
      display: none
    width: 240px

    img
      display: block
      width: 100%
      height: auto

  &__title
    width: 364px
    +media-query(tablet)
      min-width: 100%
      max-width: 100%
      padding: 28px 20px 12px 20px
      border: none
      font-size: 0
      line-height: 0
      color: rgba(0, 0, 0, 0)
      position: relative
      display: flex
      flex-direction: column-reverse

    &-active
      &:before
        +media-query(tablet)
          background: $brand-blue
          border-color: $brand-blue

      &:after
        +media-query(tablet)
          background: #ffffff
        transform: rotate(90deg)
        right: 31px
        top: 37px

    h3
      margin: 10px 0 10px 0
      +media-query(wide)
        font-size: 23px
        line-height: 23px
      +media-query(tablet)
        margin-top: 15px

    span
      font-size: 17px
      color: $brand-gray-dark
      display: block
      letter-spacing: 0.17px
      +media-query(wide)
        font-size: 15px
        line-height: 24px
      +media-query(tablet)
        font-size: 14px

      svg
        transform: translateY(2px)
        margin: 0 5px 0 1px
        +media-query(tablet)
          width: 12px
          height: 12px
          transform: translateY(1px)
          margin: 0 3px 0 0

  &__price
    width: 310px
    +media-query(tablet)
      min-width: calc(100% - 145px)
      max-width: calc(100% - 145px)
      border: none
      padding: 0 0 24px 20px
      font-size: 0
      line-height: 0
      color: rgba(0, 0, 0, 0)

    &-wrap
      display: flex
      flex-direction: row
      justify-content: space-between

    &-item
      width: 75px
      color: $brand-blue
      font-size: 25px
      line-height: 26px
      padding: 10px 0 0 0
      margin: auto // Centers horizontally in a block-level parent
      display: block // Ensures the element is block-level for margin: auto to work
      text-align: center // Centers text inside the element, if needed
      +media-query(tablet)
        font-size: 17px
        padding: 0

      span
        font-size: 17px
        line-height: 20px
        color: $brand-gray-dark
        display: block
        margin-top: 14px
        +media-query(wide)
          font-size: 15px
          line-height: 24px
        +media-query(tablet)
          font-size: 13px
          margin: -3px 0 0 0

    &-sum
      display: flex

      span
        margin-top: 5px
        +media-query(tablet)
          margin: 3px 0 0 0

  &__button
    width: 255px
    display: flex
    justify-content: center
    align-self: center
    +media-query(wide)
      padding: 0 16px
    +media-query(desktop)
      width: 100%
      padding: 20px 20px 20px 20px
    +media-query(tablet)
      min-width: 112px
      max-width: 112px
      border: none
      padding: 0px 0px 0 20px
      align-self: auto

    .button
      +media-query(desktop)
        width: 100%
      +media-query(tablet)
        min-width: 112px
        max-width: 112px
        border: none
        height: 40px
        i
          +media-query(tablet)
            display: none

  &--detail
    +media-query(tablet)
      flex-direction: column

    &-wrap
      display: none
      padding: 0 0 0 240px
      +media-query(wide)
        padding-left: 220px
      +media-query(desktop)
        padding-left: 0
    display: flex
    flex-direction: row
    padding: 30px 0 30px 0
    +media-query(wide)
      padding: 20px 0 0 0

    > div
      width: calc(50% - 15px)
      background: #ffffff
      border-radius: 8px
      box-sizing: border-box
      padding: 33px 35px 43px 34px
      +media-query(wide)
        padding: 23px 20px 33px 20px
      +media-query(tablet)
        width: 100%

      &:first-child
        +media-query(tablet)
          margin-bottom: 20px


    h4
      margin-bottom: 36px

    &--right
      margin-left: 30px
      +media-query(tablet)
        margin-left: 0

      h4
        margin-bottom: 23px

    &--left
      +media-query(tablet)
        padding-bottom: 17px

    &_title
      font-size: 20px
      font-style: normal
      font-weight: 700
      padding-bottom: 12px
      margin-bottom: 18px
      border-bottom: solid 1px #E1EAEC


  &-summary
    display: flex
    justify-content: space-between
    font-size: 19px
    line-height: 26px
    color: $brand-dark-blue
    padding: 14px 0 0px 0

    &__label
      +media-query(tablet)
        font-size: 16px

    &__summ
      +media-query(tablet)
        font-size: 18px

    span
      color: $brand-gray-medium

  &-per-trip
    display: flex
    justify-content: space-between
    color: $brand-dark-blue
    font-size: 18px
    padding: 0px 0 24px 0

    span
      color: $brand-gray-medium
      font-size: 12px

  &-time
    margin-bottom: 16px

    &__label
      color: $brand-dark-blue
      font-size: 18px
      line-height: 26px
      padding-top: 9px
      display: flex
      justify-content: space-between

      &_disabled
        color: $brand-gray-xDark
        opacity: 0.8

      &_right
        width: 150px
        display: flex
        justify-content: flex-end

    &__select
      width: 150px
      height: 34px

  &-date
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: -5px
    border-top: 1px solid $brand-gray-border

    &__label
      color: $brand-dark-blue
      font-size: 18px
      line-height: 26px
      padding-top: 9px

    &__input
      width: 150px
      display: flex
      align-items: flex-end
      justify-content: flex-end

  &__description
    margin-top: 16px

.ticket-item_open
  display: block

.ticket-item_close
  display: none